import React from 'react'
import HeyJudeUtils from '../../Utils/HeyJudeUtils';
import InputText from '../../Components/InputText';
// import '../SignIn.css';
import UserControl from '../../Control/UserControl';
import UserModel from '../../Model/UserModel';
import ConnectionType from '../../Connect/ConnectionType';

import theme from '../../Theme/theme.module.scss';
import style from './signup.module.scss';

import Picture from '../../Components/Picture';
import Button from '../../Components/Button';
import AlertMessage from '../../Components/Overlay/AlertMessage';
import InputField from "../../Components/InputField";


const logo = require('../../Assets/Img/appIcon.png')

class SignUp extends React.Component {
    static defaultProps = {
        theme: 'default'
    };


    constructor(props) {
        super(props);
        this.formData = {};
        this.state = {
            hasOTP: true,
            dialingCodes:undefined,
        }

        this.codeSelector = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.getOTP = this.getOTP.bind(this);
        this.getDialingCodes = this.getDialingCodes.bind(this);
        this.renderDialingCodes = this.renderDialingCodes.bind(this);
    }

    componentWillMount(){
        this.getDialingCodes()
    }

    async getDialingCodes(){
        let response = await UserControl.getInstance().getDialingCodes()
        if(response.result){
            this.setState({dialingCodes:response.data})
        }
    }

    validatePhoneNumber(){
        let valid = true
        let message = ""
        
        if(this.fldMobile.getCurrentValue().length < 5){
            message = "Please enter your Mobile Number with 5 digits at least"
            valid = false;
        }

        if(!valid){
            AlertMessage.getInstance().show({
            show:true,
            title:'Error',
            message:message,
            })
        }

        return valid
    }

    validateFields(){

        if(this.fldPassword.getCurrentValue().length < 1 || 
            this.fldEmail.getCurrentValue().length < 1 ||
            this.fldFirstName.getCurrentValue().length < 1 ||
            this.fldLastName.getCurrentValue().length < 1 ||
            this.fldPasswordConfirmation.getCurrentValue().length < 1){
                AlertMessage.getInstance().show({
                    show:true,
                    title:'Error',
                    message:'Enter all fields',
                })
                return false;
            }
        if(this.fldPassword.getCurrentValue() !== this.fldPasswordConfirmation.getCurrentValue()){
            AlertMessage.getInstance().show({
                show:true,
                title:'Error',
                message:'Password not confirmed',
            })
            return false;
        }
        if(this.fldPassword.getCurrentValue().length < 8){
            AlertMessage.getInstance().show({
                show:true,
                title:'Error',
                message:'Use at least 8 characters for password',
            })
            return false;
        }
        if(this.OTP.getCurrentValue().length != 6){
            AlertMessage.getInstance().show({
                show:true,
                title:'Error',
                message:'OTP should contain 6 characters',
            })
            return false;
        }
        if(!(
            this.fldEmail.getCurrentValue().includes('@') && 
            this.fldEmail.getCurrentValue().includes('.') && 
            this.fldEmail.getCurrentValue().substr(this.fldEmail.getCurrentValue().length - 2).length === 2 &&
            !this.fldEmail.getCurrentValue().substr(this.fldEmail.getCurrentValue().length - 2).includes('.')
            )){
                AlertMessage.getInstance().show({
                    show:true,
                    title:'Error',
                    message:'Invalid Email',
                })
                return false; 
        }
        return true;
    }

    renderDialingCodes(){
        if(this.state.dialingCodes === undefined){
          return null;
        }
    
        var displayList = [];
        for (var code of this.state.dialingCodes){
          var finalCode = "+" + code.phonecode;
          displayList.push(<option value={finalCode} key={code.phonecode}>{code.name}</option>)
        }
        // console.log(displayList)
        return displayList;
      }

    onInputChange(e) {
        this.formData[e.target.id] = e.target.value;
        // console.log(this.formData)
    }

    async getOTP() {
        if(this.validatePhoneNumber()){
            this.finalNumber = this.codeSelector.current.options[this.codeSelector.current.selectedIndex].value + "" + this.fldMobile.getCurrentValue()
            var response = await UserControl.getInstance().verifiOTP(this.finalNumber)
    console.log(this.finalNumber)
            if (response.result) {
                this.setState({hasOTP: true});
                // console.log("OTP response.result");
                // console.log(response.result);
            }
        }
    }


    onSubmit() {
        if(this.validateFields()){
            UserControl.getInstance().signUp({
                password: this.fldPassword.getCurrentValue(),
                email: this.fldEmail.getCurrentValue(),
                mobile: this.fldMobile.getCurrentValue(),
                firstName: this.fldFirstName.getCurrentValue(),
                lastName: this.fldLastName.getCurrentValue(),
                activationCode: this.OTP.getCurrentValue(),
                platform: 'web',
                passwordConfirmation: this.fldPasswordConfirmation.getCurrentValue(),
            });
        }
    }

    onForgotPassword() {
        UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_FORGOT_PASSWORD, {show: true})
    }
    onSignIn(){
        UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_SIGN_UP,{signUp:false})
    }

    register = () => {
        if (this.state.hasOTP) {
            return (
                <Button
                    theme={this.props.theme}
                    onClick={this.onSubmit}
                    text="Register"
                    className="mt-30"
                ></Button>
            )
        } else {
            return (
                <Button
                    theme={this.props.theme}
                    onClick={this.getOTP}
                    text="Sign Up"
                    className="mt-30"
                ></Button>
            )
        }
    };

    afterOTP = () => {
        if (this.state.hasOTP) {
            return (
                <React.Fragment>

                    <InputField
                        setReference={(ref)=>this.fldEmail=ref}
                        defaultValue=""
                        id="email"
                        placeholder="Email"
                        type="email"
                    />

                    <InputField
                        setReference={(ref)=>this.fldPassword=ref}
                        defaultValue=""
                        id="password"
                        placeholder="Password"
                        type="password"
                    />

                    <InputField
                        setReference={(ref)=>this.fldPasswordConfirmation=ref}
                        defaultValue=""
                        id="password"
                        placeholder="Password"
                        type="password"
                    />

                    <InputField
                        setReference={(ref)=>this.fldFirstName=ref}
                        defaultValue=""
                        id="username"
                        placeholder="First name"
                        type="text"
                    />

                    <InputField
                        setReference={(ref)=>this.fldLastName=ref}
                        defaultValue=""
                        id="username"
                        placeholder="Last name"
                        type="text"
                    />

                    <InputField
                        setReference={(ref)=>this.OTP=ref}
                        defaultValue=""
                        id="username"
                        placeholder="OTP"
                        type="text"
                    />

                </React.Fragment>
            )
        }
    };


    render() {
        return (
            <div className={`${theme.default} wrapper wrapper_page wrapper-sign ${theme[this.props.theme]}`}>
                <div className={`wrapper wrapper_flex-center min-h-100vh pt-30 pb-30`}>
                    <div className="wrapper wrapper_flex-column-center">
                        <Picture src={logo} alt="HeyJude Logo"></Picture>
                        <select className={`${style.FPSelect} mt-50`} ref={this.codeSelector}>
                            {this.renderDialingCodes(this.state.data)}
                        </select>

                        <InputField
                            setReference={(ref)=>this.fldMobile=ref}
                            defaultValue=""
                            theme={this.props.theme}
                            id="username"
                            placeholder="Mobile Number"
                            type="tel"
                            className="mt-15 min-w-300"
                        />
                        <div className="SignInSpacerSmall"/>
                        {this.afterOTP()}
                        {this.register()}
                        <div className="wrapper wrapper_flex-center mt-30">
                            <Button onClick={this.onSignIn} textonly="true" text="Sign In" theme={this.props.theme} className="color-white fz-14"></Button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default SignUp;