import HeyJudeUtils from "../Utils/HeyJudeUtils";
import AlertMessage from '../Components/Overlay/AlertMessage';
import AlertLoader from '../Components/Overlay/AlertLoader';

import TaskModel from '../Model/TaskModel'
import MessageControl from './MessageControl';
import ConnectionType from "../Connect/ConnectionType";
import MessageModel from "../Model/MessageModel";

class TaskControl {

  static getInstance(){
    if(TaskControl.instance === undefined){
      TaskControl.instance = new TaskControl();
    }
    return TaskControl.instance;
  }

  setViewTaskType(type){
    TaskModel.getInstance().setViewTaskType(type);
  }

  async getOpenTasks(){
    AlertLoader.getInstance().show({show:true,message:'Loading current tasks...'});
    let response = await HeyJudeUtils.JudeManager().Tasks.getOpenTasks()
    .then(data => {
      AlertLoader.getInstance().show({show:false});
      return {
        result:true,
        data:data.tasks
      }
    })
    .catch((error) => {return {result:false,data:error}});

    if(response.result){
      TaskModel.getInstance().populateOpenTaskData(response.data)
    } else {
      AlertMessage.getInstance().show({
        show:true,
        title:'Oops!',
        message:'We couldn\'t load your closed tasks.\n\nWould you like to try again?',
        acceptCallBack:TaskControl.getClosedTasks,
        cancelText:"No thanks"
      })
    }
  }

  async getClosedTasks(){
    AlertLoader.getInstance().show({show:true,message:'Loading task history...'});
    let response = await HeyJudeUtils.JudeManager().Tasks.getClosedTasks()
    .then(data => {
      AlertLoader.getInstance().show({show:false});
      return {
        result:true, data:data.tasks
      }
    })
    .catch((error) =>{return {result:false,data:error}});

    if(response.result){
      TaskModel.getInstance().populateClosedTaskData(response.data)
    } else {
      AlertMessage.getInstance().show({
        show:true,
        title:'Oops!',
        message:'We couldn\'t load your closed tasks.\n\nWould you like to try again?',
        acceptCallBack:TaskControl.getClosedTasks,
        cancelText:"No thanks"
      });
    }
  }

  async createNewTask(value = "New Task", note){

    AlertLoader.getInstance().show({show: true, message: 'Creating your Task.'});

     await HeyJudeUtils.JudeManager().Tasks.createTask(value || "New Task", note, true)
      .then(async (task)=>{
        AlertLoader.getInstance().show({show: false});
        await TaskControl.getInstance().getOpenTasks();
        await MessageControl.getInstance().getTask(task.id,true);
        TaskModel.getInstance().dispatch(ConnectionType.TYPE_HIDE_NEW_TASK_CREATE,{bypassClear:true});
      })
      .catch((error)=>{
        AlertLoader.getInstance().show({show: false});
        let title = 'Oops';
        let errorMessage = 'We are unable to create this task at the moment.';
        if(error.status === 403){
          title = 'Subscription Expired';
          errorMessage = error.errors[0];
        }
        AlertMessage.getInstance().show({
          show:true,
          title:title,
          message:errorMessage
        })
      });
  }

  reopenTask(taskId){
    HeyJudeUtils.JudeManager().Tasks.reopenTask(taskId).then(async (task)=>{
      AlertLoader.getInstance().show({show: false});
      TaskModel.getInstance().removeTaskFromClosedList(taskId);
      MessageModel.getInstance().clearMessages();
      MessageControl.getInstance().taskId = -100;
    })
    .catch((error)=>{
      AlertLoader.getInstance().show({show: false});
      let title = 'Oops';
      let errorMessage = 'We are unable to reopen this task at the moment.';
      if(error.status === 403){
        title = 'Subscription Expired';
        errorMessage = error.errors[0];
      }
      AlertMessage.getInstance().show({
        show:true,
        title:title,
        message:errorMessage
      })
    });
  }

  async markTaskAsRead(taskId) {
    HeyJudeUtils.JudeManager().Tasks.markTaskAsRead(taskId)
  }

  async cancelTask(taskId){
    TaskModel.getInstance().removeTaskFromOpenList(taskId);

    HeyJudeUtils.JudeManager().Tasks.cancelTask(taskId).catch(
      e => console.log('error @ cancel')
    );
    MessageModel.getInstance().clearMessages();
    MessageControl.getInstance().taskId = -100;
  }

  reopenTask(taskId){
    TaskModel.getInstance().removeTaskFromClosedList(taskId);

    HeyJudeUtils.JudeManager().Tasks.reopenTask(taskId);
    MessageModel.getInstance().clearMessages();
    MessageControl.getInstance().taskId = -100;
  }

  deleteTask(taskId){
    TaskModel.getInstance().removeTaskFromClosedList(taskId);
    HeyJudeUtils.JudeManager().Tasks.deleteTask(taskId);
    MessageModel.getInstance().clearMessages();
    MessageControl.getInstance().taskId = -100;
  }

  async getRating(timestamp, taskId){
    var response = await HeyJudeUtils.JudeManager().Tasks.rating(timestamp, taskId).then(
      (data) => {
        console.log(">>>",data);
        return {result:true, data:data.task_rating}
      }
    ).catch(
      (e) => {
        return {result:false, data:null}
      }
    )

    return response
  }

  async rateTask(message, unixTimeStamp, rating, taskId){
    AlertLoader.getInstance().show({show: true, message: 'Rating Task'});

    var response = await HeyJudeUtils.JudeManager().Tasks.rate(
      message,
      unixTimeStamp,
      rating,
      taskId
    ).then( (data) => {
      return {result:true, data:data.task_rating}
    }).catch((e) => {
      return {result:false, data:null}
    });

    AlertLoader.getInstance().show({show: false});

    return response
  }
}

export default TaskControl;