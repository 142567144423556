import React from 'react';
import './NewTaskInput.scss';
import TaskModel from '../../../Model/TaskModel';
import TaskControl from '../../../Control/TaskControl';
import ConnectionType from '../../../Connect/ConnectionType';
import NewTaskMediaList from './NewTaskMediaList'
import MediaControl from '../../../Control/MediaControl';
import InputText from '../../InputText';
import Button from '../../Button';
import theme from '../../../Theme/theme.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const iconUpload = require('../../../Assets/Img/iconUpload.svg')
const iconSend = require('../../../Assets/Img/iconSend.svg')
const iconClose = require('../../../Assets/Img/iconClose.png')

class NewTaskInput extends React.PureComponent {

    static defaultProps = {
        theme: 'default'
    };

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }

        this.textRef = React.createRef();
        this.noteRef = React.createRef();

        this.closeWindow = this.closeWindow.bind(this)
        this.onNewTaskCreate = this.onNewTaskCreate.bind(this);
        this.onTextInput = this.onTextInput.bind(this);
        this.setMediaSelectorRef = this.setMediaSelectorRef.bind(this)
        this.onMediaAddClick = this.onMediaAddClick.bind(this);
        this.onMediaAddChange = this.onMediaAddChange.bind(this)
        this.createNewTask = this.createNewTask.bind(this);
        this.focusInput = this.focusInput.bind(this);
    }

    componentWillMount() {
        TaskModel.getInstance().addConnection(ConnectionType.TYPE_SHOW_NEW_TASK_CREATE, this.onNewTaskCreate)
        TaskModel.getInstance().addConnection(ConnectionType.TYPE_HIDE_NEW_TASK_CREATE, this.closeWindow)
    }

    componentWillUnmount() {
        TaskModel.getInstance().removeConnection(ConnectionType.TYPE_SHOW_NEW_TASK_CREATE, this.onNewTaskCreate)
        TaskModel.getInstance().removeConnection(ConnectionType.TYPE_HIDE_NEW_TASK_CREATE, this.closeWindow)
    }

    componentDidMount() {
        this.focusInput();
    }

    closeWindow(data) {
        if (data === undefined || !data.bypassClear) {
            MediaControl.getInstance().clear();
        }

        this.setState({show: false});
    }

    focusInput() {
        if (this.textRef.current !== null && this.textRef.current !== undefined) {
            this.textRef.focus();
        }
    }

    onNewTaskCreate() {
        MediaControl.getInstance().clear();
        this.setState({show: true});
    }

    setMediaSelectorRef(ref) {
        this.mediaSelector = ref;
    }

    onMediaAddClick() {
        this.mediaSelector.click();
    }

    onMediaAddChange(e) {
        MediaControl.getInstance().addMediaToNewTaskUpload(e.target.files)
    }

    onTextInput(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    onNoteInput(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    createNewTask() {
        if (this.textRef.current !== null && this.textRef.current !== undefined) {
            TaskControl.getInstance().createNewTask(this.textRef.current.value)
        }
    }

    render() {
        if (!this.state.show) {
            return null;
        }

        return (
            <div className={`${theme[this.props.theme]} NewTaskContainer`}>
                <div className="NewTaskBlock">
                    <div className="NTClose">
                        <Button
                            theme={this.props.theme}
                            onClick={this.closeWindow}
                            textonly="true"
                            className="fz-15 p-5 icon-cancel"
                        ></Button>
                    </div>
                    <h3 className="NewTaskHeader">Create a new task</h3><br/>
                    <br/><p className="NewTaskDescription">1. Tell us how we can help</p>
                    <textarea ref={this.textRef} className="NewTaskInput" onInput={this.onTextInput}
                              placeholder="Describe your task"/>
                    <br/><p className="NewTaskDescription">2. Attach some files, if that will help us.</p>
                    <button className="NewTaskFileButton" onClick={this.onMediaAddClick}>
                        <input
                            onClick={(e) => {
                                e.target.value = ''
                            }}
                            ref={ref => this.setMediaSelectorRef(ref)}
                            className="FileSystem"
                            onChange={this.onMediaAddChange}
                            type="file"
                            accept="image/*,audio/*"
                            id="file"
                            multiple={true}
                        />
                        <FontAwesomeIcon icon="file-upload"/> &nbsp;&nbsp; Upload files
                    </button>
                    <br/><p className="NewTaskDescription">3. Create your task by using button below.</p>
                    <button className="NewTaskSubmit" onClick={this.createNewTask}>
                        <FontAwesomeIcon icon="paper-plane"/> &nbsp;&nbsp; Create Task
                    </button>

                    <NewTaskMediaList/>
                    {/* <div className="NewTaskMedia">
            MEDIA
          </div> */}

                </div>
            </div>
        )
    }
}

export default NewTaskInput;