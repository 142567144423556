import React from 'react';
import TaskModel from '../../../../Model/TaskModel';
import MessageModel from '../../../../Model/MessageModel';
import ConnectionType from '../../../../Connect/ConnectionType';
import TaskListContainer from '../TaskListContainer';
import AlertMessage from '../../../Overlay/AlertMessage';
import HeyJudeUtils from '../../../../Utils/HeyJudeUtils';
import TaskControl from '../../../../Control/TaskControl';
import Button from '../../../Button/index';

import style from './taskCreationOptions.module.scss';
import theme from '../../../../Theme/theme.module.scss';

class TaskCreationOptions extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state = {
      taskType : TaskListContainer.TYPE_OPEN
    }

    this.onTaskTypeSelected = this.onTaskTypeSelected.bind(this)
    this.onTaskSelected = this.onTaskSelected.bind(this);
    this.renderTaskOptions = this.renderTaskOptions.bind(this);

    this.onCreatePressed = this.onCreatePressed.bind(this);
    this.onCancelPressed = this.onCancelPressed.bind(this);
    this.onReopenPressed = this.onReopenPressed.bind(this);
    this.onDeletePressed = this.onDeletePressed.bind(this);
    this.validateTask = this.validateTask.bind(this);
  }

  componentDidMount(){
    TaskModel.getInstance().addConnection(ConnectionType.TYPE_TASK_TYPE_SELECTED, this.onTaskTypeSelected);
    MessageModel.getInstance().addConnection(ConnectionType.TYPE_TASK_SELECTED, this.onTaskSelected);
  }

  componentWillUnmount(){
    TaskModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_TYPE_SELECTED, this.onTaskTypeSelected)
    MessageModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_SELECTED, this.onTaskSelected);
  }

  onTaskSelected(data){
    this.taskId = data.taskId
  }

  onTaskTypeSelected(type){
   this.setState({taskType:type})
   this.taskId = undefined;
  }

  onCreatePressed(){
    TaskModel.getInstance().dispatch(ConnectionType.TYPE_SHOW_NEW_TASK_CREATE);
  }

  onCancelPressed(){
    if(this.validateTask()){
      TaskControl.getInstance().cancelTask(this.taskId)
    }
  }

  onReopenPressed(){
    if(this.validateTask()){
      TaskControl.getInstance().reopenTask(this.taskId)
      
    }
  }

  onDeletePressed(){
    if(this.validateTask()){
      TaskControl.getInstance().deleteTask(this.taskId)
      
    }
  }

  validateTask(){
    if(this.taskId === undefined){
      AlertMessage.getInstance().show({
        show:true,
        title:'Unfortunately, you are unable to do that.',
        message:'Please select a Task',
      })
      return false;
    }
    return true;
  }

  renderTaskOptions(){
    return (
      <Button theme={this.props.theme} secondary text="Create New Task" className="uppercase fz-15 h-40" onClick={this.onCreatePressed}></Button>
    )
  }

  render(){
    return (
      <div className={`${theme[this.props.theme]} wrapper wrapper_flex-center zi-2 wrapper-creation-options`} key="TaskCreationOptionsInner">
        {this.renderTaskOptions()}
      </div>
    )
  }

}

export default TaskCreationOptions;