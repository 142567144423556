import React from 'react';
import './MMenu.scss';
import MModalButton from '../MModalButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MMenu extends React.PureComponent{

	constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="MMenu_Hey">
      	<a href="http://athome.microsoft.co.za/privacy" target="_blank" className="MenuLink">Privacy Statement</a> &nbsp;
      	<a href="http://athome.microsoft.co.za/terms" target="_blank" className="MenuLink">Terms & Conditions</a><br/><br/><br/>
      	<a href="https://athomemicrosoft.azurewebsites.net/movies" target="_blank" className="MenuLinkBig">Buy Movie Tickets</a><br/><br/><br/><br/>
      	<a href="https://athomemicrosoft.azurewebsites.net/sec_discountshopping" target="_blank" className="MenuLinkBig">Browse Shopping Deals</a>
      </div>
    );
  }

}

export default MMenu;
