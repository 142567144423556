import React from 'react'
import TaskListContainer from '../TaskListContainer/index';
import TaskControl from '../../../../Control/TaskControl';
import TaskModel from '../../../../Model/TaskModel';
import ConnectionType from '../../../../Connect/ConnectionType';
import Button from '../../../Button/index';

import style from './taskTypeSwitch.module.scss'
import theme from '../../../../Theme/theme.module.scss';



class TaskTypeSwitch extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
    
    this.state = {
      type:TaskListContainer.TYPE_OPEN,
    }

    this.updateTaskType = this.updateTaskType.bind(this);
    this.switchToCurrentTasks = this.switchToCurrentTasks.bind(this)
    this.switchToTaskHistory = this.switchToTaskHistory.bind(this)
    this.isCurrentTasksSelected = this.isCurrentTasksSelected.bind(this);
  }

  componentWillMount(){
    TaskModel.getInstance().addConnection(ConnectionType.TYPE_TASK_TYPE_SELECTED, this.updateTaskType)
  }

  componentWillUnmount(){
    TaskModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_TYPE_SELECTED, this.updateTaskType)
  }

  updateTaskType(type){
    this.setState({
      type:type,
    })
  }

  switchToCurrentTasks(){
    if(this.state.type === TaskListContainer.TYPE_OPEN){
      return;
    }

    this.setState({type:TaskListContainer.TYPE_OPEN});
    //this.props.updateTaskType(TaskListContainer.TYPE_OPEN)
    
    TaskControl.getInstance().setViewTaskType(TaskListContainer.TYPE_OPEN)
  }

  switchToTaskHistory(){
    if(this.state.type === TaskListContainer.TYPE_CLOSED){
      return
    }

    this.setState({type:TaskListContainer.TYPE_CLOSED});
    //this.props.updateTaskType(TaskListContainer.TYPE_CLOSED)

    TaskControl.getInstance().setViewTaskType(TaskListContainer.TYPE_CLOSED)
  }
  
  isCurrentTasksSelected(){
    if (this.state.type === TaskListContainer.TYPE_OPEN){
      return true;
    } else {
      return false;
    }
  }

  render(){
    return(
      <div className={`${theme[this.props.theme]} wrapper wrapper_flex-center  wrapper-type-switch zi-2`} key="TaskTypeSwitchInner">
        <Button theme={this.props.theme} text="Current Tasks" active={this.isCurrentTasksSelected()} onClick={this.switchToCurrentTasks} className="uppercase bold fz-15 h-40"></Button>
        <Button theme={this.props.theme} text="Task History" active={!this.isCurrentTasksSelected()} onClick={this.switchToTaskHistory} className="ml-15 uppercase bold fz-15 h-40"></Button>
      </div>
    )
  }

}

export default TaskTypeSwitch;