import React from 'react';
import ConnectionType from '../../../Connect/ConnectionType'
import TaskModel from '../../../Model/TaskModel'
import TaskControl from '../../../Control/TaskControl'
import TaskItem from './TaskItem/index';

class TasksClosed extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.onNewData = this.onNewData.bind(this);
    this.renderList = this.renderList.bind(this);

    this.state = {
      data:TaskModel.getInstance().getData().closedTasks 
    }
  }

  componentDidMount(){
    TaskModel.getInstance().addConnection(ConnectionType.TYPE_TASK_CLOSED_DATA, this.onNewData);
    TaskControl.getInstance().getClosedTasks();
  }

  componentWillUnmount(){
    TaskModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_CLOSED_DATA, this.onNewData);
  }

  onNewData(data){
    this.setState({
      data:data
    });

    this.forceUpdate()
  }

  renderList(dataSet){
    var displayList = [];

     for(var dataItem of dataSet){
       displayList.push(
         <TaskItem theme={this.props.theme} key={dataItem.id} data={dataItem}/>
       )
    }

    if(displayList.length === 0){
      displayList.push(
        <div className="NoTaskContainer" key="NoTaskContainer">
          <div className="NoTaskContainerText" key="NoTaskContainerText">No Closed Tasks</div>
        </div>
      )
    }

    return displayList
  }

  render(){   
    return (
      <div className="wrapper wrapper_tasks-list" key="TasksClosedInner">
        {this.renderList(this.state.data)}
      </div>
    )
  }
}

export default TasksClosed;
