import React from 'react';
import './UserProfile.scss';
import UserModel from '../../../Model/UserModel';
import UserControl from '../../../Control/UserControl';
import ConnectionType from '../../../Connect/ConnectionType';
import Picture from '../../Picture';
import Button from '../../Button';

import theme from '../../../Theme/theme.module.scss';

import InputField from '../../InputField';

import MMenu from '../../MMenu';
import MModalButton from "../../MModalButton";
import Validations from "../../../Utils/Validations";

class UserProfile extends React.PureComponent {

  static defaultProps = {
    theme: 'default'
  };

    constructor(props) {
        super(props);
        this.userData = UserModel.getInstance().getData();
        this.state = {
            show: false,
            showEdit:false,
            showCardDetails:false,
            transition:'hide',
            noCover: true,
            iframeUrl: '',
            cardDetailsForm: true,
            threeDSecureUrl: "",
            userFirstName: this.userData.firstName,
            userLastName: this.userData.lastName,
            userEmail: this.userData.email,
            profileImage: this.userData.profileImage,
            userImage: null,
            userImageType: '',
            userImageValid: true,
            userImageFile: null
        };

        this.onToggleProfile = this.onToggleProfile.bind(this);
        this.onSignOutPressed = this.onSignOutPressed.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.renderCardDetails = this.renderCardDetails.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.currentImage = this.currentImage.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
    }

    componentDidMount() {
        UserModel.getInstance().addConnection(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, this.onToggleProfile)
    }

    componentWillUnmount() {
        UserModel.getInstance().removeConnection(ConnectionType.TYPE_TOGGLE_PROFILE_VIEW, this.onToggleProfile)
    }

    onToggleProfile() {
      if(this.state.show){
        this.setState({
          transition: 'hide',
          showEdit: false,
          showCardDetails: false,
          checkoutId:'',
          userImage: null
        });
        setTimeout(()=>{
          this.setState({
            noCover: true,
            show: !this.state.show
        });
        },450)
      }
      else{
        this.setState({
          noCover: false,
          show: !this.state.show
        });
        setTimeout(()=>{
          this.setState({transition: 'show'});
        },50)
      }
    }

    onSignOutPressed() {
        UserControl.getInstance().signOut()
    }

    selectImage(e){
      let file = e.target.files[0];
      if(file){

        let url = URL.createObjectURL(file);
        let img = new Image();
        
        img.src = url;
        img.onload = () =>{
          if(
            img.width < 2000 && 
            img.height < 2000 && 
            (file.type.toLowerCase().includes('jpeg') || file.type.toLowerCase().includes('png'))
          ){
            this.setState({
              userImage: url,
              userImageType: file.type,
              userImageValid: true,
              userImageFile: file
            });
          }
          else{
            this.setState({
              userImageValid: false
            })
          }
        }
      }
    }

    settingProfile = () => {
        this.setState({showCardDetails:false});
        this.setState({showEdit:!this.state.showEdit});
    };

    settingCardDetails = async () => {
        this.setState({showEdit:false});
        let getPaymentLink = UserControl.getInstance().getPaymentLink();
        await getPaymentLink.then(response => {
            this.setState({paymentLink:response.data});
        });
        let response = await UserControl.getInstance().getUserPaymentMethods();
        if(response){
            let paymentMethods = this.createPaymentMethods(response.payment_methods);
            this.setState({paymentMethods:paymentMethods});
            this.setState({showCardDetails:!this.state.showCardDetails});
        }
    };

    saveProfile = async () => {
        let emailIsValid = Validations.validateEmail(this.fldEmail.getCurrentValue());
        if (this.state.userImageValid && emailIsValid === true) {
            let response = await UserControl.getInstance().updateProfile({
                firstName: this.fldFirstName.getCurrentValue(),
                lastName: this.fldLastName.getCurrentValue(),
                email: this.fldEmail.getCurrentValue(),
                profileImage: this.state.userImageFile,
                greenerChoices: this.userData.greenerChoices,
                pushNotifications: this.userData.pushNotifications,
            });
            if (response) {
                const details = {
                    firstName: this.fldFirstName.getCurrentValue(),
                    lastName: this.fldLastName.getCurrentValue(),
                    email: this.fldEmail.getCurrentValue()
                };
                this.setState({
                    showEdit: !this.state.showEdit,
                    userFirstName: details.firstName,
                    userLastName: details.lastName,
                    userEmail: details.email,
                    profileImage: this.state.userImage
                });
                UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_PROFILE_UPDATED,{profileImage: this.state.profileImage});
            }
        }
    };

    currentImage(){
      if(this.state.userImage){
        return this.state.userImage;
      } else {
        return this.state.profileImage;
      }
    }

    createPaymentMethods(methods) {
        let paymentMethods = "";
        for (let j = 0; j < methods.length; j++) {
            paymentMethods += "<p style='color:white'>Card No : ************" + methods[j].last_four_digits + "</p><br/>"
        }
        return paymentMethods
    };

    renderCardForm(){
        return <div>
          <h3>Link a card below</h3>
          <div className="IframeContainer">
            <iframe id="payment_link" align={'center'} scrolling={'yes'} width="1000" height="700"  src={this.state.paymentLink}></iframe>
          </div>
        </div>
    };

    renderCardDetails(){
        if(this.state.showCardDetails){
            return(
                <React.Fragment>
                    <div className="MMenu_Hey">
                        <h3>Current linked payment methods</h3>
                        <br/>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.paymentMethods
                            }}>
                        </div>
                        <MModalButton title="Link a payment method">
                            {this.renderCardForm()}
                        </MModalButton>
                    </div>
                </React.Fragment>
            )
        }
    }

    renderEdit(){
      if(this.state.showEdit){
        return(
          <React.Fragment>
            <div className="userImageWrapp">
              <img className="userImage" src={this.currentImage()} alt="Profile image" />
            </div>
            <p className="fz-13 mt-20 bold">First Name</p>
            <InputField
                theme={this.props.theme}
                setReference={(ref) => this.fldFirstName = ref}
                defaultValue={this.state.userFirstName}
                id="username"
                placeholder="First Name"
                type="text"
                disabled={'disabled'}
            />
            <p className="fz-13 bold mt-20">Last Name</p>
            <InputField
                theme={this.props.theme}
                setReference={(ref) => this.fldLastName = ref}
                defaultValue={this.state.userLastName}
                id="userLastName"
                placeholder="Last name"
                type="text"
                disabled={'disabled'}
            />
            <p className="fz-13 bold mt-20">Email</p>
            <InputField
                theme={this.props.theme}
                className="mt-10 h-40"
                setReference={(ref) => this.fldEmail = ref}
                defaultValue={this.state.userEmail}
                id="userEmail"
                placeholder="Email"
                type="email"
                disabled={'disabled'}
            />
              <p className="fz-13 bold mt-20">Phone Number</p>
              <InputField
                  theme={this.props.theme}
                  className="mt-10 h-40"
                  defaultValue={this.userData.mobile}
                  id="userEmail"
                  placeholder="Phone Number"
                  type="text"
                  disabled={'disabled'}
              />
          </React.Fragment>
        )
      }
    }

    hideContainer(){
      if (this.state.transition === 'hide') {
          return 'userProfileHide';
      }
      else{
        return '';
      }
    }
    hideCover(){
      if (this.state.transition === 'hide') {
          return 'wrapper_cover-hide';
      }
      else{
        return '';
      }
    }
    noCover(){
      if(this.state.noCover){
        return {display: 'none'};
      }
      else{
        return {};
      }
    }
    truncate(str, n){
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };

    render() {
  
        return (
          <React.Fragment>
            <div className={`${theme[this.props.theme]} ${this.hideContainer()} userProfileContainer`}>
              <div className="userAvatarContainer shrink-0">
                <div onClick={this.onToggleProfile} className="wrapper hide-xl">
                  <Picture user src={this.state.profileImage} alt='Avatar'/>
                </div>
                <div className="wrapper show-xl">
                  <Picture user src={this.state.profileImage} alt='Avatar'/>
                </div>
                <div className="wrapper wrapper_flex-column-center ml-15">
                  <p className="username max-w-100 fz-15 bold">{this.truncate(this.state.userFirstName + ' ' + this.state.userLastName, 20)}</p>
                  <p className="userphone fz-12 mt-3">{this.userData.mobile}</p>
                </div>
                <div className="wrapper wrapper_flex-center ai-end flex-1">
                  <Button
                    theme={this.props.theme}
                    textonly="true"
                    className="icon-credit-card fz-20 ml-15 p-10"
                    onClick={this.settingCardDetails}
                  ></Button>
                  <Button
                    theme={this.props.theme}
                    textonly="true"
                    onClick={this.settingProfile} 
                    className="icon-pencil fz-20 p-10"
                  ></Button>
                  <br/>
                </div>
              </div>
              <div className="wrapper">
                <MMenu></MMenu>
              </div>

              <div className="wrapper wrapper_flex-column-center mt-30 shrink-0">
                {this.renderEdit()}
              </div>

              <div className="wrapper shrink-0">
                  {this.renderCardDetails()}
              </div>

              <div className="wrapper wrapper_flex-column-center mt-70 shrink-0">
                <a
                  theme={this.props.theme}
                  textonly="true"
                  onClick={this.onSignOutPressed}
                  className="userLogout icon-logout fz-20 p-10 hide-xl"
                > Sign Out</a>
                <a
                  theme={this.props.theme}
                  textonly="true"
                  onClick={this.onToggleProfile}
                  className="userCloseButton fz-20 p-10 hide-xl"
                > X </a>
              </div>

            </div>
            <div onClick={this.onToggleProfile} style={this.noCover()} className={`${this.hideCover()} wrapper wrapper_cover wrapper_cover-fixed hide-xl`}></div>
            
          </React.Fragment>
        )
    }
}

export default UserProfile;